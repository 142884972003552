module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: 'Wipiway Blog', // Navigation and Site Title
  siteTitleAlt: 'The blog of an egghead creator', // Alternative Site title for SEO
  siteTitleShort: 'Wipiway Blog', // short_name for manifest
  siteUrl: 'https://wipiway.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'This is where they post things!',
  author: 'Wipiway LLC', // Author for schemaORGJSONLD
  organization: 'Wipiway LLC',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@wipiway', // Twitter Username
  ogSiteName: 'Wipiway.com Blockchain Blog', // Facebook Site Name
  ogLanguage: 'en_US',
  googleAnalyticsID: '',

  // Manifest and Progress color
  themeColor: '#593ea7',
  backgroundColor: '#593ea7',

  // Social component
  twitter: 'https://twitter.com/wipiway/',
  twitterHandle: '@wipiway',
  github: '',
  linkedin: 'https://www.linkedin.com/in/singhniranjan/',
}
